import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Divider,
  Popover,
  colors,
  createTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import LeaveTypeDataTable from "./LeaveTypeDataTable";
import LeavesDataTable from "./LeavesDataTable";
import { useNavigate } from "react-router-dom";
import workingHourPolicy from '../../../../Assets/Files/Working_Hours_Policy__Favcy_VB.pdf';
import internLeavePolicy from '../../../../Assets/Files/Intern_Leave_Policy_FavcyVB.pdf';
import LeavePolicy from '../../../../Assets/Files/Leave_Policy_Favcy_VB.pdf';
import DressCodePolicy from '../../../../Assets/Files/Dress_Code_FavcyVB.pdf';

const theme = createTheme();

const LeavesSection = (props) => {
  let {
    allLeaves = [],
    appliedData = [],
    handleApplyLeave,
    userPagination,
    allPagination,
    handlePageChange,
    handleEditClick,
    userData,
    handleShowHolidays,
    handleShowLeaves,
    activeSection = "all"
  } = props;

  const [bodyData, setBodyData] = useState([]);
  const headerList = ["Leave Type", "Quantum", ""];

  const [activeTab, setActiveTab] = useState(activeSection);
  const [loading, setLoading] = useState(false);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  const navigate = useNavigate();

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
  }, [screenSize]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    manipulateDataToUse();
  }, [allLeaves]);

  useEffect(() => {
    setLoading(true);
    setInterval(() => {
      setLoading(false);
    }, 1000);
  }, [activeTab]);

  const manipulateDataToUse = async () => {
    setBodyData(allLeaves || []);
  };

  const style = {
    leftToggleBTN: {
      borderRadius: "20px",
      background: activeTab == "all" ? "#801F52" : "#FFFFFF",
      color: activeTab == "all" ? "#FFF" : "#801F52",
      // border: " 1px solid #801F52 !important",
      px: 1,
      "& :focus, :hover": {
        background: activeTab == "all" ? "#801F52" : "#FFFFFF",
        color: activeTab == "all" ? "#FFF" : "#801F52",
      },
    },
    rightToggleBTN: {
      borderRadius: "20px",
      background: activeTab == "availed" ? "#801F52" : "#fff",
      color: activeTab == "availed" ? "#FFF" : "#801F52",
      // border: " 1px solid #801F52",
      px: 1,
      "& :focus, :hover": {
        background: activeTab == "availed" ? "#801F52" : "#FFFFFF",
        color: activeTab == "availed" ? "#FFF" : "#801F52",
      },
    },
    commonBTN: {
      borderRadius: "50px",
      background: "#fff",
      color: "#801F52",
      fontWeight: 500,
      px: 1,
      py: 0,
      mb: 1,
      boxShadow: "4px 4px 40px 0px #0000000D",
      [theme.breakpoints.down("md")]: { py: 1, px: 3.5, mb: 0.3 },
    },
    ApplyLeaveBTN: {
      borderRadius: "50px",
      color: "#fff",
      background: "#801F52",
      px: 1,
      mb: 1,
      fontWeight: 600,
      boxShadow: "4px 4px 40px 0px #0000000D",
      "&:hover": {
        color: "#fff",
        background: "#801F52",
      },
      [theme.breakpoints.down("md")]: { width: "88%" },
    },
  };

  return (
    <>
      <Box sx={{ mt: -5, [theme.breakpoints.down("md")]: { mt: 1 }, }}>
        <div className="w-full flex flex-wrap justify-center md:justify-start gap-1 md:gap-2" style={{width: screenSize.width < 768 ?  "auto" :screenSize.width-715 +"px",}}>
          <Box
            disableElevation
            variant="contained"
            aria-label="Disabled button group"
            className="flex justify-center flex-grow md:mb-2 rounded-full px-1 py-1 bg-white"
            sx={{ borderRadius: "50px" }}
          >
            <Button
              sx={style.leftToggleBTN}
              className="flex-grow"
              disabled={loading}
              onClick={() => setActiveTab("all")}
            >
              My Leaves
            </Button>
            <Button
              sx={style.rightToggleBTN}
              className="flex-grow"
              disabled={loading}
              onClick={() => setActiveTab("availed")}
            >
              All Employees
            </Button>
          </Box>

          <Button className="flex-grow" sx={style.commonBTN} onClick={()=>{handleShowHolidays(true)}}>View Holidays</Button>
          <Button className="flex-grow" sx={style.commonBTN} aria-describedby={id} onClick={handleClick}>Company Policies</Button>
          <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
              }}
          >
              <Box className="flex px-4 py-4 gap-2 flex-wrap">
                  {/* <Button className="flex-grow" sx={{...style.ApplyLeaveBTN, mb: 0, px:3, boxShadow: "1px 2px 2px #00000051"}} onClick={()=>{handleShowLeaves(true)}}>Leave Policy</Button> */}
                  <Button className="flex-grow" sx={{...style.ApplyLeaveBTN, mb: 0, px:3, boxShadow: "1px 2px 2px #00000051"}} onClick={() => window.open(LeavePolicy, '_blank')}>Leave Policy</Button>
                  <Button className="flex-grow" sx={{...style.ApplyLeaveBTN, mb: 0, px:3, boxShadow: "1px 2px 2px #00000051"}} onClick={() => window.open(internLeavePolicy, '_blank')}>Intern Leave Policy</Button>
                  <Button className="flex-grow" sx={{...style.ApplyLeaveBTN, mb: 0, px:3, boxShadow: "1px 2px 2px #00000051"}} onClick={() => window.open(workingHourPolicy, '_blank')}>Working Hours Policy</Button>
                  <Button className="flex-grow" sx={{...style.ApplyLeaveBTN, mb: 0, px:3, boxShadow: "1px 2px 2px #00000051"}} onClick={() => window.open(DressCodePolicy, '_blank')}>Dress Code Policy</Button>
              </Box>
          </Popover>

          <Button
            className="flex-grow" sx={style.ApplyLeaveBTN}
            onClick={() => {
              handleApplyLeave(true);
              navigate("/dashboard/leaveTracker/add")
            }}
          >
            Apply for Leave
          </Button>
        </div>
        {loading ? (
          <Box className="w-full flex justify-center h-20 items-center">
            <CircularProgress />
          </Box>
        ) : activeTab == "all" ? (
          <LeavesDataTable
            tableData={appliedData}
            pagination={userPagination}
            handlePageChange={handlePageChange}
            handleEditClick={handleEditClick}
            userData={userData}
            />
          ) : bodyData ? (
            <LeavesDataTable
            tableData={bodyData}
            pagination={allPagination}
            showingAll={true}
            handlePageChange={handlePageChange}
            handleEditClick={handleEditClick}
            userData={userData}
          />
        ) : null}
      </Box>
    </>
  );
};

export default LeavesSection;
